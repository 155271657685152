exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-game-index-tsx": () => import("./../../../src/pages/game/index.tsx" /* webpackChunkName: "component---src-pages-game-index-tsx" */),
  "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-camping-game-start-gathering-firewood-index-mdx": () => import("./../../../src/pages/game/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/game-pages/CampingGame/CampingGameStart/GatheringFirewood/index.mdx" /* webpackChunkName: "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-camping-game-start-gathering-firewood-index-mdx" */),
  "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-camping-game-start-index-mdx": () => import("./../../../src/pages/game/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/game-pages/CampingGame/CampingGameStart/index.mdx" /* webpackChunkName: "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-camping-game-start-index-mdx" */),
  "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-camping-game-start-set-up-tents-index-mdx": () => import("./../../../src/pages/game/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/game-pages/CampingGame/CampingGameStart/SetUpTents/index.mdx" /* webpackChunkName: "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-camping-game-start-set-up-tents-index-mdx" */),
  "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-first-night-index-mdx": () => import("./../../../src/pages/game/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/game-pages/CampingGame/FirstNight/index.mdx" /* webpackChunkName: "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-first-night-index-mdx" */),
  "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-first-night-investigate-smoke-follow-stench-index-mdx": () => import("./../../../src/pages/game/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/game-pages/CampingGame/FirstNight/InvestigateSmoke/FollowStench/index.mdx" /* webpackChunkName: "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-first-night-investigate-smoke-follow-stench-index-mdx" */),
  "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-first-night-investigate-smoke-index-mdx": () => import("./../../../src/pages/game/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/game-pages/CampingGame/FirstNight/InvestigateSmoke/index.mdx" /* webpackChunkName: "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-first-night-investigate-smoke-index-mdx" */),
  "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-first-night-investigate-smoke-investigate-bunker-index-mdx": () => import("./../../../src/pages/game/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/game-pages/CampingGame/FirstNight/InvestigateSmoke/InvestigateBunker/index.mdx" /* webpackChunkName: "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-first-night-investigate-smoke-investigate-bunker-index-mdx" */),
  "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-first-night-investigate-smoke-keep-your-distance-index-mdx": () => import("./../../../src/pages/game/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/game-pages/CampingGame/FirstNight/InvestigateSmoke/KeepYourDistance/index.mdx" /* webpackChunkName: "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-first-night-investigate-smoke-keep-your-distance-index-mdx" */),
  "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-first-night-investigate-smoke-stay-at-night-index-mdx": () => import("./../../../src/pages/game/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/game-pages/CampingGame/FirstNight/InvestigateSmoke/StayAtNight/index.mdx" /* webpackChunkName: "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-first-night-investigate-smoke-stay-at-night-index-mdx" */),
  "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-first-night-leave-forrest-index-mdx": () => import("./../../../src/pages/game/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/game-pages/CampingGame/FirstNight/LeaveForrest/index.mdx" /* webpackChunkName: "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-first-night-leave-forrest-index-mdx" */),
  "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-return-to-camp-index-mdx": () => import("./../../../src/pages/game/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/game-pages/CampingGame/ReturnToCamp/index.mdx" /* webpackChunkName: "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-return-to-camp-index-mdx" */),
  "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-return-to-camp-run-away-index-mdx": () => import("./../../../src/pages/game/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/game-pages/CampingGame/ReturnToCamp/RunAway/index.mdx" /* webpackChunkName: "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-return-to-camp-run-away-index-mdx" */),
  "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-return-to-camp-try-to-sleep-fight-monsters-with-fire-index-mdx": () => import("./../../../src/pages/game/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/game-pages/CampingGame/ReturnToCamp/TryToSleep/FightMonstersWithFire/index.mdx" /* webpackChunkName: "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-return-to-camp-try-to-sleep-fight-monsters-with-fire-index-mdx" */),
  "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-return-to-camp-try-to-sleep-fight-monsters-with-knives-index-mdx": () => import("./../../../src/pages/game/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/game-pages/CampingGame/ReturnToCamp/TryToSleep/FightMonstersWithKnives/index.mdx" /* webpackChunkName: "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-return-to-camp-try-to-sleep-fight-monsters-with-knives-index-mdx" */),
  "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-return-to-camp-try-to-sleep-index-mdx": () => import("./../../../src/pages/game/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/game-pages/CampingGame/ReturnToCamp/TryToSleep/index.mdx" /* webpackChunkName: "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-return-to-camp-try-to-sleep-index-mdx" */),
  "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-return-to-camp-try-to-sleep-run-through-forrest-index-mdx": () => import("./../../../src/pages/game/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/game-pages/CampingGame/ReturnToCamp/TryToSleep/RunThroughForrest/index.mdx" /* webpackChunkName: "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-camping-game-return-to-camp-try-to-sleep-run-through-forrest-index-mdx" */),
  "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-music-test-index-mdx": () => import("./../../../src/pages/game/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/game-pages/musicTest/index.mdx" /* webpackChunkName: "component---src-pages-game-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-game-pages-music-test-index-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

